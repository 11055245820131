.admin-table .header-sortable {
  white-space: nowrap;
}
.admin-table .header-sortable:hover {
  cursor: pointer;
}

.admin-table.ui.compact.table .expanded-row > td {
  padding: 0;
  border-top: none;
}

.admin-table.ui.table thead th:empty,
.admin-table.ui.table tbody td:empty {
  padding: 0;
}
.admin-table .expanded-row .expanded-row-contents {
  margin: 0 !important;
}

.admin-table .expanded-row .expanded-row-contents.hidden {
  display: none;
  padding: 0;
}

.admin-table.ui.table tbody tr.shaded {
  background-color: rgba(0, 0, 50, 0.02);
}

.admin-table.ui.table tbody .expandable .expand-column-chevron:before {
  content: '\f078';
}

.admin-table.ui.table tbody .expandable.open .expand-column-chevron:before {
  content: '\f077';
}

.admin-table tr.expandable {
  transition: all 0.125s ease;
}

.admin-table .ui.table.admin-table tr:first-child td {
  border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.admin-table .ui.table.admin-table {
  border-radius: 0;
}

.admin-table .expanded-row .expanded-row-contents .responsive-table-container {
  margin: 0 !important;
}

.admin-table tr.clickable {
  cursor: pointer;
}

.admin-table tr.expandable.open {
  background: #f4f6f8 !important;
}

.expanded-assignments-table .responsive-table .responsive-table,
.responsive-table-container .responsive-table .responsive-table {
  background: #fafafa;
}

.expanded-assignments-table .responsive-table-container .responsive-table-container .responsive-table-container,
.expanded-assignments-table .responsive-table-container .responsive-table-container .responsive-table-container td,
.responsive-table-container .responsive-table-container .responsive-table-container,
.responsive-table-container .responsive-table-container .responsive-table-container td {
  background-color: #fff;
}

.admin-table .expandable.open + .expanded-row .Collapsible__contentOuter .admin-table {
  border-left: 3px solid #df057b !important;
}

.admin-table .admin-table tr.expandable:hover {
  background: #fff !important;
}

.admin-table .admin-table tr.expandable.open {
  background: #f4f6f8 !important;
}

.admin-table .admin-table .expandable.open + .expanded-row .Collapsible__contentOuter {
  border-left: none;
}

.admin-table .last-row .ui.dropdown .menu {
  top: auto;
  bottom: 3em;
}

/* List view styles */

.responsive-table-container.list-view {
  overflow-x: visible;
}

.responsive-table-container.list-view .ui.table.admin-table {
  border-top: none;
  border-left: none;
  border-right: none;
}

.responsive-table-container.list-view .ui.table.admin-table td {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
}

.responsive-table-container.list-view .ui.table.admin-table th {
  padding: 0.5em 1.25em;
}

.responsive-table-container.list-view .admin-table.ui.table tbody tr.shaded {
  background: #fff;
}

.responsive-table-container.list-view .ui.table thead th {
  background: #fff;
}

/* Temp overwrite of forced margin */
.enrollments .member-dashboard-assignments .responsive-table-container {
  margin: 0 !important;
}

@media screen and (max-width: 675px) {
  .responsive-table-container.list-view {
    overflow-x: auto;
  }

  .admin-table .last-row .ui.dropdown .menu {
    bottom: auto;
  }
}
