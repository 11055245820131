.ui.message.post-assessment-survey-banner {
  position: fixed;
  bottom: 1em;
  right: 6em;
  max-width: 27em;
  text-align: left;
  z-index: 50;
  display: flex;
}

.ui.message.post-assessment-survey-banner .icon {
  margin-top: 0 !important;
  margin-right: 0.5em !important;
}

@media screen and (max-width: 675px) {
  .ui.message.post-assessment-survey-banner {
    bottom: 0em;
    left: 0;
    right: 0;
    max-width: none;
  }
}
