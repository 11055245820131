.conversation-links .link-container > div {
  display: inline-block;
}
.conversation-links .link-container {
  display: flex;
  align-items: center;
}

.conversation-links > .segment:hover {
  cursor: pointer;
}

.conversation-links .link-container .link-content {
  width: calc(100% - 55px);
  padding: 0 1em;
}

.conversation-links .link-container .link-content p {
  margin: 0;
}

.conversation-links .link-container .link-icon {
  font-size: 2em;
  width: 35px;
  margin-top: 12px;
  text-align: center;
  color: #4183c4;
}

.conversation-links .link-container .link-icon.right {
  font-size: 1.5em;
  width: 20px;
}

.conversation-links .link-container .content-heading {
  font-weight: 700;
  font-size: 1.125em;
}

.conversation-links .link-container .content-text {
  color: #00000099;
}

.conversation-links .segment {
  margin: 0;
}

.conversation-links .segment + .segment {
  margin-top: 0.5em;
}

.conversation-links .segment {
  margin: 0;
}

.conversation-links .segment + .segment {
  margin-top: 0.5em;
}

.conversation-links .ui.segment {
  box-shadow: 0px 2px 3px 1px rgba(21, 22, 23, 0.2);
}

.conversation-links .ui.segment:hover {
  box-shadow: 0px 0px 2px 1px rgba(21, 22, 23, 0.1);
}
