.ui.cards > .card.search-cip-promo {
  margin: 1.5em 0.5em;
}

.search-cip-promo .content-wrapper,
.search-cip-promo .icon-container,
.search-upgrade-promo .content-wrapper,
.search-upgrade-promo .icon-container {
  text-align: center;
}

.search-cip-promo .icon-container,
.search-upgrade-promo .icon-container {
  padding: 4em 0 1em 0;
}

.search-cip-promo .icon-container .icon,
.search-upgrade-promo .icon-container .icon {
  color: #80808057;
  font-size: 4em;
}

.search-cip-promo .content-container .header,
.item.search-item-container.search-upgrade-promo .header {
  font-size: 1.5em;
}

.search-cip-promo .content-container .description {
  font-size: 0.9375em;
  color: #637381;
}

.search-cip-promo .action-text,
.search-business-promo .content .action-text,
.search-upgrade-promo .action-text {
  color: #4183c4;
  margin-top: 1em !important;
}

.item.search-item-container.search-cip-promo .icon-container,
.item.search-item-container.search-upgrade-promo .icon-container {
  padding-top: 1em;
}

.item.search-item-container.search-cip-promo .icon,
.item.search-item-container.search-upgrade-promo .icon {
  margin: 0 0 0 0.25rem;
}

.item.search-item-container.search-cip-promo,
.item.search-item-container.search-upgrade-promo {
  padding: 2em 0 !important;
}

.item.search-item-container.search-cip-promo .content .header,
.item.search-item-container.search-upgrade-promo .content .header {
  font-weight: 600 !important;
}

.item.search-item-container.search-cip-promo .icon-container + .content,
.item.search-item-container.search-upgrade-promo .icon-container + .content {
  padding-left: 1.5em;
}

.search-cip-promo .content.icon-container .icon,
.search-upgrade-promo .content-icon-container .icon {
  margin-top: 0.3em;
}

.checkout-page .search-upgrade-promo .description,
.checkout-page .search-upgrade-promo .action-text {
  font-size: 1em;
}

.search-business-promo .header {
  font-weight: 600;
}

.search-business-promo .description {
  font-size: 0.9375em;
  color: #637381;
}

.search-business-promo .logo-container .image {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .item.search-item-container.search-upgrade-promo {
    margin: 1em 0em !important;
    padding: 0em !important;
  }

  .search-cip-promo .icon-container,
  .search-upgrade-promo .icon-container {
    display: inline-block;
    height: 100%;
    padding: 0;
    vertical-align: top;
    margin: 1.35em 1em 0em 0;
  }

  .search-cip-promo .content-container {
    text-align: left;
    display: inline-block;
    width: calc(100% - 98px);
  }

  .search-business-promo .logos-container {
    display: none !important;
  }
}
