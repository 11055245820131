.slick-dots li button:before {
  /* color: rgb(226 3 122) !important; Hot pink for the active dot */
  line-height: 30px;
  font-size: 12px;
}

.slick-dots li,
.slick-dots li button,
.slick-dots li button:before {
  width: 30px;
  height: 30px;
}

@media (max-width: 767px) {
  .slick-dots li button:before {
    font-size: 14px;
    line-height: 30px;
  }
  .slick-dots li,
  .slick-dots li button,
  .slick-dots li button:before {
    width: 20px;
    height: 30px;
  }

  .slick-dots {
    white-space: nowrap;
  }
}
