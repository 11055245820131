/* override styles when printing */
@media print {
  .primary-sidebar,
  .app-content > .top.menu-container > .menu-items-container *:not(:first-child), /* Hides items in top bar, minus Cybrary logo */
  .ui.breadcrumb,
  .app-footer {
    display: none;
  }

  .cyb-content {
    display: block;
  }

  .cyb-app {
    height: auto !important;
    overflow: visible !important;
  }

  .cyb-router {
    height: auto !important;
  }

  .organization .dashboard .learning-hours canvas {
    width: 100% !important;
    height: 13rem !important;
  }

  .organization .dashboard .four.cards canvas,
  .organization .dashboard .two.cards canvas {
    width: 100% !important;
    height: 7rem !important;
  }

  .organization .dashboard .header-container .ui.grid.header-content > .row > [class*='eight wide computer'].column {
    width: 50% !important;
    display: inline-block !important;
  }

  .organization .dashboard .header-container > .ui.grid > .row > .admin-items {
    display: none;
  }

  .organization .dashboard .ui.four.cards > .card.stat-card {
    width: calc(50% - 1.5em);
  }

  .organization .dashboard .group-cards .card .header {
    font-size: 1em !important;
  }

  .organization .dashboard .group-cards .card {
    width: calc(25% - 1.5em);
  }

  .admin-table th {
    line-height: 1em;
  }

  .admin-table th,
  .admin-table td {
    font-size: 0.75em;
  }

  .admin-table .header-sortable {
    white-space: normal;
  }

  .admin-table .header-sortable i {
    display: none;
  }

  .organization .dashboard .filter-container .ui.grid > .row > [class*='sixteen wide'].column {
    width: 20% !important;
    display: inline-block;
  }

  .organization .dashboard .filter-container .ui.grid > .row > [class*='sixteen wide'].column.date-range-filter-container {
    width: 80% !important;
  }

  .organization .dashboard .stuck-container.fixed-container > div {
    height: 0 !important;
  }

  .organization .dashboard .filterable-content .ui.fixed.top.sticky {
    position: relative;
    height: auto !important;
    top: 0 !important;
  }

  .organization .dashboard .leaderboard-container .highlight-bar {
    display: none;
  }
}
