/* purgecss start ignore */

@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Black.ttf');
  font-weight: 900;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Thin.ttf');
  font-weight: 100;
}

/* Checkbox and Radio style overrides */
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  accent-color: black;
}

/* Remove outline from icons with outline in name */
.icon.outline {
  outline: 0;
}

/*******************************
  Has Markdown Inside
*******************************/
/* Headers */
.has-markdown-inside h1,
.has-markdown-inside h2,
.has-markdown-inside h3,
.has-markdown-inside h4,
.has-markdown-inside h5,
.has-markdown-inside h6 {
  @apply mt-8 mb-4;
}
.has-markdown-inside h1 {
  @apply text-5xl font-black;
}
.has-markdown-inside h2 {
  @apply text-2xl font-black;
}
.has-markdown-inside h3 {
  @apply text-lg font-bold;
}
.has-markdown-inside h4 {
  @apply text-base font-semibold;
}
.has-markdown-inside h5 {
  @apply text-xs font-semibold;
}
.has-markdown-inside h6 {
  @apply text-2xs font-semibold;
}

.has-markdown-inside-text-white h1,
.has-markdown-inside-text-white h2,
.has-markdown-inside-text-white h3,
.has-markdown-inside-text-white h4,
.has-markdown-inside-text-white h5,
.has-markdown-inside-text-white h6 {
  @apply text-white;
}

/* Links */
.has-markdown-inside a {
  @apply text-cyb-pink-500 underline cursor-pointer;
}
.has-markdown-inside a:hover {
  @apply text-black;
}

.has-markdown-inside.secondary-links a {
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.has-markdown-inside.secondary-links a:hover {
  color: rgba(226, 3, 122, var(--tw-text-opacity));
}
/* Paragraph */

/* Default paragraph margin -- Allowed as any class placed on actual elements can override this default */
p {
  @apply mb-4;
}
.has-markdown-inside p {
  @apply text-sm font-normal mb-4 leading-6;
}
.has-markdown-inside.no-p-margin p {
  @apply mb-0 leading-6;
}
.has-markdown-inside-text-white p {
  @apply text-white;
}
/* Lists */
.has-markdown-inside ul,
.has-markdown-inside ol {
  @apply mb-4 ml-8;
  list-style: revert;
}
.has-markdown-inside li {
  @apply mb-3;
}
.has-markdown-inside li > p,
.has-markdown-inside li > ul,
.has-markdown-inside li > ol {
  @apply mb-0;
}
/* Blockquotes */
.has-markdown-inside blockquote {
  @apply p-2 mx-6 bg-gray-100 mb-4 border-l-4 border-gray-400 italic;
}
.has-markdown-inside blockquote > p {
  @apply mb-0;
}
/* Tables */
.has-markdown-inside td,
.has-markdown-inside th {
  @apply px-2 py-1 border border-gray-400;
}
.has-markdown-inside tr:nth-child(odd) {
  @apply bg-gray-100;
}
.has-markdown-inside table {
  @apply mb-6;
}
/* Wrap any inline highlights `that are really long`, but don't modify
   the setting for codeblocks (inside
), which are rendered in as:
   <pre><code>...
*/
.has-markdown-inside :not(pre) > code.language-text {
  white-space: pre-line;
}

/*******************************
  React Phone Number Input fields
*******************************/

.PhoneInputInput {
  @apply appearance-none border bg-white rounded w-full py-2 px-2 mb-1 border-gray-300 placeholder-gray-600;
}

.PhoneInputError .PhoneInputInput {
  @apply border-red-500 bg-red-100 placeholder-gray-400;
}

.PhoneInputInput:focus {
  @apply outline-none border border-blue-400;
}

/* old immersive v2 styles */
@media (min-width: 1024px) and (min-height: 750px) {
  .video-padding {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 714px) {
  .video-size {
    max-width: 52rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 720px) {
  .video-size {
    max-width: 54rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 750px) {
  .video-size {
    max-width: 57.5rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 780px) {
  .video-size {
    max-width: 60.5rem !important;
  }
}

@media (min-width: 920px) and (min-height: 800px) {
  .video-size {
    max-width: 66rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 800px) {
  .video-size {
    max-width: 63rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 840px) {
  .video-size {
    max-width: 67.5rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 865px) {
  .video-size {
    max-width: 70rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 880px) {
  .video-size {
    max-width: 71.75rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 905px) {
  .video-size {
    max-width: 74.5rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 930px) {
  .video-size {
    max-width: 78rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 960px) {
  .video-size {
    max-width: 80.5rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 1000px) {
  .video-size {
    max-width: 84.75rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 1060px) {
  .video-size {
    max-width: 91rem !important;
  }
}
/* end old immersive v2 styles */

.immersive-sidebar {
  height: calc(100vh - 64px);
}

.form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}

/* purgecss end ignore */
@tailwind utilities;

@layer utilities {
  .flex-basis-1\/3 {
    flex-basis: 33.333333%;
  }
  .flex-basis-1\/2 {
    flex-basis: 50%;
  }
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar {
    scroll-behavior: smooth;
  }
  .scrollbar::-webkit-scrollbar {
    width: 12px;
  }
  .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 2px solid #fff;
  }
}
