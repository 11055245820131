.email-reminder.ui.modal > .header:not(.ui) {
  padding-bottom: 0.5em;
  font-weight: 600;
}

.email-reminder div .ui.grid > .row > [class*='middle aligned'].column {
  margin-top: 3em;
}

.email-reminder div .ui.header:first-child {
  font-weight: 600;
  margin-bottom: 0em;
}

.email-reminder i.massive.icon {
  font-size: 6em;
}

.email-reminder div .ui.header + p {
  color: #949090;
}

.email-reminder .access-account {
  margin-left: 3.5em;
  padding-bottom: 0em !important;
}

.email-reminder .access-account p {
  color: #949090;
  font-size: 0.8em;
}

@media only screen and (max-width: 425px) {
  .email-reminder .action-btn .ui.primary.button {
    margin-top: 2em;
  }
}
