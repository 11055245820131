/**
 * A handful of CSS overrides for embedded components.
 **/

.ui.placeholder.segment.practice-test .field {
  /* was max-width: 15rem; */
  max-width: 25rem;
}

.assessment-embedded-container {
  overflow-y: auto;
  height: calc(100vh - 280px);
}

.cannot-embed-container,
.relaunch-embed-container {
  height: calc(100vh - 284px);
}

.embedded-item .mobile-wall {
  height: calc(100vh - 270px);
}

.interstitial-embed .upgrade-paywall {
  height: calc(100vh - 284px);
}

@media screen and (max-width: 768px) {
  .assessment-embedded-container {
    height: calc(100vh - 330px);
  }
}
