.cyb-app {
  height: 100vh;
  overflow: hidden;
  background: #fff;
}

.cyb-app::-webkit-scrollbar {
  width: 0em;
  height: 0em;
}
.cyb-content {
  flex: 1;
  display: flex;
  overflow: auto;
}
.cyb-content::-webkit-scrollbar {
  width: 0em;
  height: 0em;
}
.slide-content-container,
.cyb-router {
  height: calc(100vh - 112px);
}
.cyb-router {
  background: #f4f6f8;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cyb-router.no-secondary-nav,
.cyb-router.no-secondary-nav .slide-content-container {
  height: calc(100vh - 64px);
}

.cyb-router.immersive {
  height: 100vh;
}

.cyb-router.promo-banner-adjustment,
.cyb-router.promo-banner-adjustment .slide-content-container {
  height: calc(100vh - 165px);
}

/* wildcard selector: https://www.w3schools.com/cssref/sel_attr_contain.php */
div[class*='banner-adjustment'],
div[class*='banner-adjustment'] .slide-content-container {
  height: calc(100vh - 200px);
}

.cyb-router::-webkit-scrollbar {
  width: 0.5em;
  height: 0em;
}

.slack-icon {
  background: url('https://images.ctfassets.net/kvf8rpi09wgk/3uesBbU4CoGoQSjwnTRqa6/b94b3e12ef3c13888cefcc01c1f26272/Slack_Mark.svg');
  height: 32px;
  width: 32px;
  display: block;
}

.slack-icon-small {
  background: url('https://images.ctfassets.net/kvf8rpi09wgk/3uesBbU4CoGoQSjwnTRqa6/b94b3e12ef3c13888cefcc01c1f26272/Slack_Mark.svg');
  height: 14px;
  width: 14px;
  display: block;
}

body > div.ui.page.modals.transition.visible.active > div {
  position: static !important;
}

.responsive-table-container {
  overflow-x: auto;
}

.responsive-table-container .ui.table thead th {
  font-size: 0.9375em;
}

.admin-table-search .ui.basic.button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: -2.75em;
}

.app-footer .footer-community-container a.item.footer-link:hover,
.app-footer .footer-community-container a.item.footer-link:hover i,
.app-footer div.list a.item.footer-link.tos:hover,
.app-footer div.list a.item.footer-link.pp:hover {
  color: #949090 !important;
}

.app-footer .footer-community-container a.item.footer-link {
  text-transform: uppercase;
  color: #474444;
  font-weight: 600;
}

.career-path {
  overflow: hidden;
  width: 100% !important;
}

.career-path.ui.grid,
.ui.grid.profile-info {
  margin: 0 !important;
}

.career-path > .row.banner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #fff !important;
}

.career-path .banner-container .app-header-container .details-content {
  color: #fff !important;
}

.career-path .banner-content p {
  width: 100%;
  color: #fff !important;
}

.career-path .banner-container > .gradient-overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4));
}

.career-path .banner-container .ui.segment.banner-content {
  margin-left: 3vw;
  padding: 8vh 0 25vh 0;
}

.career-path .banner-content img {
  max-width: 100%;
  display: block;
}

.career-path .banner-action {
  margin-top: 3em;
}

.cancellation-page .progress-stepper-container div div div div a,
.cancellation-page .progress-stepper-container div div div div span {
  line-height: 32px !important;
}

.cancellation-page .progress-stepper-container div div div div:hover a,
.cancellation-page .progress-stepper-container div div div:hover a {
  cursor: pointer;
}

.ui.star.rating .active.icon {
  background: 0 0 !important;
  color: #e2037a !important; /* Cybrary pink */
  text-shadow: none !important;
}

.ui.star.rating .icon.selected,
.ui.star.rating .icon.selected.active {
  background: 0 0 !important;
  color: #f472b6 !important; /* lighter pink */
  text-shadow: none !important;
}

/* Force break footer links and logo to prevent clashing */
@media only screen and (max-width: 823px) {
  .app-footer .footer-info {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .cyb-router > .container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* CLAB Hiding local mouse cursor (to show machines cursor) */
.clab-container {
  cursor: none;
}

.immersive-lesson-frame {
  height: calc(100vh - 128px);
}

.immersive-complete-frame {
  height: calc(100vh - 63px);
}

/* Overrides for 2023 */
.immersive-2023-lesson-frame {
  height: calc(100vh - 64px);
}

.immersive-2023-complete-frame {
  height: calc(100vh - 63px);
}

/* Immersive Page style overrides (v2) */
@media only screen and (max-width: 1023px) {
  .cyb-router,
  .slide-content-container {
    height: calc(100vh - 73px);
  }
  .immersive-frame-v3 {
    /* primary nav 72px, secondary nav 64px */
    height: calc(100vh - 136px);
  }
  .immersive-frame {
    /* top nav 72px */
    height: calc(100vh - 72px);
  }
  .immersive-frame.immersive-legacy-content,
  .immersive-frame-v3.immersive-legacy-content {
    /* top nav 72px, bottom nav 76px */
    height: calc(100vh - 148px);
  }
}
@media only screen and (min-width: 1024px) {
  .cyb-router.promo-banner-adjustment,
  .cyb-router.promo-banner-adjustment .slide-content-container {
    height: calc(100vh - 172px);
  }
  .immersive-frame,
  .immersive-frame-v3 {
    /* top nav 72px, 2nd nav 64px, bottom nav 76px */
    height: calc(100vh - 136px);
  }
  .immersive-frame.immersive-legacy-content,
  .immersive-frame-v3.immersive-legacy-content {
    /* top nav 72px, 2nd nav 64px, bottom nav 76px */
    height: calc(100vh - 212px);
  }
}

/* TAILWIND INTEGRATION OVERRIDES */
.ui.segment {
  box-shadow: none;
  border: 0.8px solid #cccccc;
}

table th {
  background: #f5f5f5 !important;
}

.ui.dropdown.icon {
  background: #f5f5f5 !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 0.8125rem;
}

/* Datepicker default styles */
:root {
  --duet-color-primary: #000;
  --duet-color-text: #333;
  --duet-color-text-active: #fff;
  --duet-color-placeholder: #666;
  --duet-color-button: #fff;
  --duet-color-surface: #fff;
  --duet-color-overlay: rgba(0, 0, 0, 0.8);
  --duet-color-border: #cccccc;

  --duet-font: 'Inter', Roboto, Helvetica, Arial, sans-serif;
  --duet-font-normal: 400;
  --duet-font-bold: 600;

  --duet-radius: 2px;
  --duet-z-index: 600;
}
.duet-date__toggle {
  z-index: 0;
}
.duet-date__input,
.duet-date__toggle {
  border: none !important;
  background: transparent;
}
.duet-date__input:disabled,
.duet-date__toggle:disabled {
  opacity: 0.5;
}
.duet-date__input {
  padding: 4px 60px 4px 12px;
}
.duet-date__input:focus {
  border-color: rgb(96, 165, 250);
  box-shadow: none;
}
.duet-date__toggle:focus {
  box-shadow: 0 0 0 1px rgb(96, 165, 250);
}
.duet-date__dialog-content {
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.compact-date-wrapper .date-picker-wrapper {
  min-height: 38px;
}
.compact-date-wrapper .duet-date__input {
  font-size: 14px;
}
/* Removes the focus outline from mouse focus - Retains it for keyboard tabbing */
:focus:not(:focus-visible) {
  outline: none;
}

/* Site default styles (mostly defaults that existed globally with Semantic when previously included) */
p:last-child {
  margin-bottom: 0;
}
