.banner-container .ui.basic.button.book-toggle {
  box-shadow: none;
  color: #fff !important;
}

.banner-container .ui.basic.button.book-toggle:hover {
  box-shadow: none;
  background: none !important;
  cursor: pointer;
  color: #fff !important;
}

.banner-container .ui.basic.button:focus {
  background: none !important;
}

.bookmark-icon:hover {
  cursor: pointer;
}
