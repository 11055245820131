.ui.pointing.secondary.app-page.menu {
  background: none;
}

.ui.pointing.secondary.app-page.vertical-tabs.menu {
  display: block;
  border-bottom: none;
}

.app-page .app-header .app-header-container {
  padding-top: 32px;
  padding-bottom: 32px;
}

@media only screen and (min-width: 1200px) {
  .app-page .app-header .app-header-container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.app-header div h2.ui.browse-promo-title {
  color: #fff !important;
  margin-right: 1em;
  font-weight: 900;
  font-size: 48px;
}

.app-header div .ui.grid > .row .review-share-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (min-width: 992px) {
  .app-header div .ui.grid > .row .review-share-section {
    justify-content: flex-end;
  }
}

.app-page .tab-container .module-collapsible .icon {
  transition: all 200ms ease;
}
.app-page .tab-container .module-collapsible .Collapsible__trigger.is-open .icon {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Safari prior 9.0 */
  transform: rotateZ(180deg);
  margin-top: 7px;
}

.app-page .tab-container .module-collapsible {
  margin: 1em 0;
}

.app-page .tab-container .module-collapsible .section-duration {
  font-size: 0.875em;
}

.app-page .tab-container .module-collapsible .row:hover {
  cursor: pointer;
}

.display-linebreak {
  white-space: pre-line;
}

.flex {
  display: flex;
}

.buttons {
  display: flex;
  margin-bottom: 2em;
}

.app-page .content-profile .circular.image {
  max-width: 7em;
}

.app-page .content-profile .circular.image img {
  border-radius: 50% !important;
}

.app-page .ui.basic.segment.tab-container {
  padding: 0;
}

.app-page .ui.basic.segment.tab-container > div {
  padding-top: 3rem;
  padding-right: 0rem;
  background-color: #fff;
}

.app-page .tab-container .ui.secondary.pointing.menu {
  border-width: 1px;
}

.app-page .ui.basic.segment.tab-container .active.item {
  border-bottom: 3px solid#df057b;
  border-width: 4px;
}

.app-page .ui.basic.segment.tab-container .item {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.app-page .tab-container .tab-pane {
  border: none;
  box-shadow: none;
  background-color: #fff;
}

.app-page .content-profile {
  padding: 2em 0;
}

.app-page .content-profile .content {
  padding: 1em 0 !important;
}

.app-page .content-profile .profile-info .row {
  padding: 0;
}

.app-page {
  overflow: hidden;
  width: 100% !important;
}

.app-page.ui.grid,
.ui.grid.profile-info {
  margin: 0 !important;
}

.app-page > .row.banner-container,
.app-page > .row.vendor-banner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #fff;
}

.app-page .banner-content p {
  width: 100%;
  color: #fff;
}

.app-page .banner-container > .gradient-overlay,
.app-page .vendor-banner-container > .gradient-overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4));
}

.app-page .banner-container .ui.segment.banner-content,
.app-page .vendor-banner-container .ui.segment.banner-content {
  margin-left: 3vw;
  padding: 8vh 0 25vh 0;
}

.app-page .banner-content img {
  max-width: 100%;
  display: block;
}

.app-page > .ui.stackable.grid > .row:nth-child(2) {
  margin-top: -8.75em;
}

.app-page .banner-action {
  margin-top: 3em;
}

.content-provider-container {
  padding: 0 !important;
}

.responsive-height {
  height: 100% !important;
}

.app-page .component-container.ui.segment {
  margin: 0.5em 0;
}

.app-page .course-components .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  height: 2.35em;
  width: 2.35em;
  margin: auto;
  border-radius: 50%;
  background: #637381;
}

.app-page .course-components .icon-container.pink {
  background: #e14696;
}

.app-page .course-components .icon-column {
  align-self: center !important;
}

.app-page .course-components .icon-container .icon {
  margin: 0;
  margin-bottom: 2px;
  color: #fff;
}

.app-page .course-components .icon-container .edit.outline.icon {
  margin: 0 0 4px 4px;
}

.app-page .course-components .icon-container .clipboard.icon {
  font-size: 1.125em;
}

.app-page .course-components .text-container span {
  font-size: 0.9375em;
}

.app-page .course-components .text-container {
  align-self: center !important;
}

@media only screen and (max-width: 990px) {
  .banner-container button.enrollment-btn,
  .vendor-banner-container button.enrollment-btn {
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-container button.syllabus-btn,
  .vendor-banner-container button.syllabus-btn {
    min-width: 200px;
  }
  .banner-container .ui.medium.fluid.button.syllabus-btn,
  .vendor-banner-container .ui.medium.fluid.button.syllabus-btn {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .banner-container button.syllabus-btn,
  .vendor-banner-container button.syllabus-btn {
    min-width: 200px;
  }
}
@media only screen and (max-width: 1000px) {
  .ui.grid > [class*='three column'].row > .column.app-header-buttons .button {
    max-width: 10em;
    margin-right: 15em;
  }
}

.instructor-card img.ui.floated.image {
  margin-bottom: 0em;
}

.instructor-card .instructor-image-container.circular {
  flex: none;
}

.instructor-card .instructor-image-container.circular img.ui.floated.image {
  border-radius: 50%;
}

.instructor-card.vendor-card img.ui.floated.image {
  border-radius: 0;
  margin-bottom: 0em;
}

.ui.card.instructor-card > .content > .header:not(.ui) {
  margin-top: 0.5em;
  font-size: 1em;
}

.instructor-card.ui.card .meta {
  margin-top: 0.5em;
  font-size: 0.75em;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.85);
}

.ui.card.instructor-card .label {
  margin-top: 1em;
}

@media only screen and (max-width: 780px) {
  .banner-container .reviews-button .review-star-icon,
  .banner-container .reviews-button .syllabus-download-icon,
  .vendor-banner-container .reviews-button .review-star-icon,
  .vendor-banner-container .reviews-button .syllabus-download-icon {
    line-height: 1.25 !important;
    vertical-align: middle;
    font-size: 1.5em !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner-container .reviews-button .review-star-icon,
  .banner-container .reviews-button .syllabus-download-icon,
  .vendor-banner-container .reviews-button .review-star-icon,
  .vendor-banner-container .reviews-button .syllabus-download-icon {
    line-height: 1 !important;
    vertical-align: middle;
    font-size: 2em !important;
  }
}

.banner-container .ui.basic.button.reviews-button,
.banner-container .ui.basic.button.syllabus-button,
.banner-container .ui.basic-button.share-button,
.vendor-banner-container .ui.basic.button.reviews-button,
.vendor-banner-container .ui.basic.button.share-button,
.vendor-banner-container .ui.basic.button.syllabus-button {
  box-shadow: none;
}

.banner-container .ui.basic.button.reviews-button:hover,
.banner-container .ui.basic.button.syllabus-button:hover,
.banner-container .ui.basic.button.share-button:hover,
.vendor-banner-container .ui.basic.buttom.reviews-button:hover,
.vendor-banner-container .ui.basic.button.syllabus-button:hover,
.vendor-banner-container .ui.basic.button.share-button:hover,
.activity-container .ui.basic.button.share-button:hover,
.immersive-mobile-pusher .ui.basic.button.reviews-button:hover,
.immersive-mobile-pusher .ui.basic.button.syllabus-button:hover,
.immersive-mobile-pusher .ui.basic.button.share-button:hover,
.immersive-mobile-pusher .ui.basic.button.share-button:hover {
  box-shadow: none;
  background: none !important;
  cursor: pointer;
}

.banner-container .ui.basic.button.book-toggle:hover i.bookmark-icon,
.banner-container .ui.basic.button.reviews-button:hover i.review-star-icon,
.banner-container .ui.basic.button.syllabus-button:hover i.syllabus-download-icon,
.banner-container .ui.basic.button.share-button:hover i.share-icon,
.vendor-banner-container .ui.basic.button.book-toggle:hover i.bookmark-icon,
.vendor-banner-container .ui.basic.button.reviews-button:hover i.review-star-icon,
.vendor-banner-container .ui.basic.button.syllabus-button:hover i.syllabus-download-icon,
.vendor-banner-container .ui.basic.button.share-button:hover i.share-icon,
.activity-container .ui.basic.button.share-button:hover i.share-icon,
.activity-container i.bookmark-icon:hover,
.immersive-mobile-pusher .ui.basic.button.book-toggle:hover i.bookmark-icon,
.immersive-mobile-pusher .ui.basic.button.reviews-button:hover i.review-star-icon,
.immersive-mobile-pusher .ui.basic.button.syllabus-button:hover i.syllabus-download-icon,
.immersive-mobile-pusher .ui.basic.button.share-button:hover i.share-icon,
.immersive-mobile-pusher .ui.basic.button.share-button:hover i.share-icon,
.immersive-mobile-pusher i.bookmark-icon:hover {
  color: #767676 !important;
}

.banner-container .ui.basic.button.book-toggle:hover i.bookmark-icon.bookmark-icon.marked,
.vendor-banner-container .ui.basic.button.book-toggle:hover i.bookmark-icon.bookmark-icon.marked,
.activity-container i.bookmark-icon.green:hover,
.immersive-mobile-pusher .ui.basic.button.book-toggle:hover i.bookmark-icon.bookmark-icon.marked,
.immersive-mobile-pusher i.bookmark-icon.green:hover {
  color: #e03997 !important;
}

.banner-container .ui.basic.button:focus,
.vendor-banner-container .ui.basic.button:focus,
.activity-container .ui.basic.button:focus,
.immersive-mobile-pusher .ui.basic.button:focus,
.immersive-mobile-pusher .ui.basic.button:focus {
  background: none !important;
}

div.ui.page.modals.transition.visible.active .share-container {
  display: flex;
  justify-content: space-evenly;
}

.share-modal .content .ui.container .btn-copy:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.btn-copy {
  margin-left: 1em;
  width: 20%;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .btn-copy {
    margin-left: 1em;
    width: 30%;
    cursor: pointer;
  }

  .app-page .ui.container.app-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.condensed-card-container .ratings-container i:before,
.search-item-container .ratings-container i:before {
  text-shadow: none !important;
}

.condensed-card-container .ratings-container .star-outline,
.search-item-container .ratings-container .star-outline {
  opacity: 0.5 !important;
}

.activity-container .ratings-container .star-outline {
  opacity: 1 !important;
}

.ratings-container {
  height: 21px;
}

.title-card-container.ui.card > .content .star.icon:hover {
  color: #ffe623;
}

.ui.card > .content .star.icon {
  opacity: 1 !important;
}

.feeedback-button-container .ratings-container .star,
.onboarding .ratings-container .star,
.activity-container .ratings-container .star,
.condensed-card-container .ratings-container .star,
.search-item-container .ratings-container .star,
.title-card-container .ratings-container .star,
.app-header-container .ratings-container .star {
  color: #ffe623;
}

.feeedback-button-container .ratings-container .star::before,
.title-card-container .ratings-container:hover .star::before,
.app-header-container .ratings-container:hover .star::before {
  color: #ffe623;
}

.feeedback-button-container .ratings-container .star-outline,
.onboarding .ratings-container .star-outline,
.activity-container .ratings-container .star-outline,
.condensed-card-container .ratings-container .star-outline,
.search-item-container .ratings-container .star-outline,
.title-card-container .ratings-container .star-outline,
.app-header-container .ratings-container .star-outline {
  color: rgba(0, 0, 0, 0.15);
}

.onboarding .ratings-container.inverted .star-outline {
  color: #fff;
}

.app-header-container .ratings-container:hover .star-outline::before,
.title-card-container .ratings-container:hover .star-outline::before {
  color: #fff;
}

.ratings-container.no-click:hover .star-outline::before {
  color: rgba(0, 0, 0, 0.15);
}

.ratings-container span:focus {
  outline: none !important;
}

.ui.basic.button.reviews-button:hover,
.ui.basic.button.book-toggle:hover {
  background: none !important;
}

.ratings-container i:before {
  text-shadow: 0 -1px 0 #767676, -1px 0 0 #767676, 0 1px 0 #767676, 1px 0 0 #767676 !important;
}

.onboarding .ratings-container.inverted i.star:before {
  text-shadow: 0 -1px 0 #ffe623, -1px 0 0 #ffe623, 0 1px 0 #ffe623, 1px 0 0 #ffe623 !important;
}
