.live-circle {
  background: red;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.live-circle-blink {
  animation: blinker 1.5s linear infinite;
}

.cybrary-live .play.circle {
  font-size: 2em;
  margin: 7px 0;
}

.cybrary-live .live-card * {
  font-size: 1em;
}

.cybrary-live .live-card .detailsBlock div {
  font-size: 0.935em;
  line-height: 1.25em;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
