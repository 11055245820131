.awards-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.awards-container {
  margin-top: 0;
}

.certificate-popup-container {
  width: 125px !important;
}

.certificate-popup-container img {
  cursor: pointer !important;
}

.certificate-title {
  font-size: 0.7em;
  margin: 0.5em !important;
}

.certificate-completed {
  font-size: 0.55em;
  margin: auto;
  margin-left: 0.5em;
  white-space: nowrap;
}

.badge-popup-container,
.badge-popup {
  width: 50px !important;
}

.certificate-popup {
  width: 75px;
}

.share-cert-container .social-media-btn {
  outline: -webkit-focus-ring-color auto 0;
  margin: 1em;
  cursor: pointer;
}

.share-cert-container .btn-copy:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.share-cert-container .btn-copy {
  margin-left: 1em;
  width: 25%;
  cursor: pointer;
}

@media only screen and (min-width: 550px) {
  .share-cert-container {
    margin: 2em !important;
  }
}

@media only screen and (max-width: 768px) {
  .share-cert-container .btn-copy {
    margin-left: 1em;
    width: 40%;
    cursor: pointer;
  }

  .share-cert-container .social-media-btn {
    margin: 0 0 1em 0.5em;
  }
}

@media only screen and (max-width: 568px) {
  .share-cert-container {
    margin: 0.25em !important;
  }

  .share-cert-container.ui[class*='vertically divided'].grid > .row:first-child > .column {
    margin: 1em !important;
  }

  .share-cert-container.ui[class*='center aligned'].grid > .row > .column {
    padding: 0.25em;
  }

  .share-cert-container .ui.container {
    margin: 0em !important;
  }

  .share-cert-container .share-cert-title {
    font-size: 1em !important;
  }
}
