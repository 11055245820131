.upgrade-options {
  max-width: 850px;
}

.upgrade-options .request-license-column .button {
  font-size: 12px;
  padding: 0.6875em 0.75em 0.6875em;
}

.upgrade-options .horizontal-divide {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .upgrade-options .enterprise-upgrade-options {
    display: block !important;
  }

  .upgrade-options .horizontal-divide {
    display: table !important;
  }

  .upgrade-options .vertical-divide {
    display: none !important;
  }
}
