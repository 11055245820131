.stat-card {
  box-shadow: none !important;
  border: 0.5px solid #cccccc !important;
}

.stat-card .summary-container .statistic {
  width: 50%;
}

.stat-card .summary-container .ui.statistic + .ui.statistic {
  margin-left: 0;
}

.stat-card .summary-container .substats .statistic {
  justify-content: center;
}

.stat-card .summary-container .substats .statistic .label {
  margin-left: 0.5rem;
}

.five .stat-card .summary-container .substats .statistic .label {
  font-size: 0.875rem !important;
}

.stat-card .summary-container .substats .statistic .value {
  font-size: 2rem !important;
}

.stat-card .summary-container .horizontal.statistic {
  width: 100%;
}

.stat-card .summary-container .substats .horizontal.statistic {
  margin-bottom: 0;
}

.stat-card .summary-container .ui.horizontal.statistic + .ui.horizontal.statistic {
  margin-left: 0;
  margin-top: 0.5em;
}

.stat-card .summary-container .statistic .label {
  font-weight: 400;
  color: #637381;
}

.stat-card .summary-container .substats .value {
  margin-bottom: 0.125em;
}

.stat-card .summary-container > .statistic,
.stat-card .summary-container > .statistic div,
.stat-card .summary-container > .statistic div + div,
.stat-card .summary-container > .statistic span {
  width: 100%;
  text-align: center;
}

.stat-card .summary-container > .statistic .value {
  letter-spacing: -6px;
}

.stat-card .summary-container .substats > .statistic .value {
  letter-spacing: -2px;
}

.stat-card.horizontal-bar,
.stat-card.doughnut {
  justify-content: center;
}

.stat-card.horizontal-bar .display,
.stat-card.doughnut .display {
  width: 100%;
}

.stat-cards-group .card.stat-card .compact .ui.small.statistic > .value {
  letter-spacing: -2px;
}

.stat-cards-group .card.stat-card .compact.single-stat .ui.small.statistic > .value {
  font-size: 2.5rem !important;
  margin-top: 0.25em;
}

.stat-cards-group .card.stat-card .summary-container.small > .statistic > .value {
  font-size: 3.25rem !important;
}

.stat-cards-group .card.stat-card .summary-container.medium > .statistic > .value {
  font-size: 4rem !important;
}

.stat-cards-group .card.stat-card .summary-container.large > .statistic > .value {
  font-size: 5rem !important;
}

.stat-cards-group.four .card.stat-card .summary-container > .statistic > .value {
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stat-cards-group.four .card.stat-card .summary-container > .statistic {
  height: 8.5rem;
}

.stat-container h3.ui.header {
  margin-top: 0;
}

.ui.cards > .card.stat-card {
  display: inline-block;
}

.empty-stats-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.empty-stats-container .empty-stats-content {
  text-align: center;
  color: #637381;
  width: 100%;
}

.empty-stats-content .icon {
  display: block;
  margin: auto;
  font-size: 2.5em;
  color: #80808057;
}

.leaderboard-container td.highlight {
  position: relative;
  background-color: transparent;
  font-weight: 600;
}

.leaderboard-container .highlight-bar.hidden {
  width: 0 !important;
}

.leaderboard-container .highlight-bar {
  position: absolute;
  height: 70%;
  left: 0;
  top: 15%;
  background-color: rgba(87, 218, 255, 0.30196078431372547);
  z-index: -0;
  transition: all 0.5s linear;
  width: 0;
}

.leaderboard-container td.placement-col {
  font-weight: 600;
}

.leaderboard-container td.trophy-col {
  max-width: 1.75em;
  padding: 0 !important;
}

.leaderboard-container .trophy {
  margin-right: 0.5em;
  font-size: 1.5em;
}

.leaderboard-container .trophy.placed-1 {
  color: rgba(255, 215, 0, 0.5);
}

.leaderboard-container .trophy.placed-2 {
  color: rgba(192, 192, 192, 0.5);
}

.leaderboard-container .trophy.placed-3 {
  color: rgba(202, 171, 6, 0.5);
}

.stat-container {
  position: relative;
}

.organization .dashboard .stat-container .stat-links {
  text-align: right;
  margin-right: -0.75em;
  color: #707070;
}

.organization .features-summary .screenshot-wrapper:after {
  content: ' ';
  width: 100%;
  height: 7em;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, transparent 32%, #fff, #fff);
}

.organization .features-summary .screenshot-wrapper {
  position: relative;
}

.organization .features-summary .screenshot-wrapper img {
  width: 100%;
}

.organization .features-summary .header {
  margin-top: 0.25em;
}

.organization .features-summary > .segment {
  padding: 2em 1em;
}

.workrole-report .grow-message-container {
  margin-top: 2em;
}

.workrole-report .workrole-skill-container {
  padding: 0;
}

.workrole-report .score-proficiency-container * {
  display: block;
}

.workrole-report .score-proficiency-container .score {
  font-size: 2em;
}

.workrole-report .tooltip-container {
  height: 100%;
  text-align: right;
}

.workrole-report .tooltip-container .icon {
  color: #637381;
}

.workrole-report .proficiency {
  text-transform: uppercase;
  color: #637381;
  vertical-align: middle;
}

.workrole-report .score-proficiency-container {
  position: absolute;
  left: calc(50% - 60px);
  height: 65px;
  width: 120px;
  text-align: center;
}

.workrole-report .workrole-skill-container .score {
  font-size: 1.5em;
  vertical-align: middle;
  margin: 0 1em 0 0.5em;
}

.workrole-report .summary-row {
  margin-bottom: 2em;
}

.grid.workrole-report > .row.workrole-skill-container {
  padding-bottom: 1em;
  padding-top: 0;
}

.workrole-report .workrole-skill-container .skill-description {
  color: #637381;
  font-size: 0.9375em;
  margin: 1em 0;
}

.workrole-report .doughnut-container canvas {
  height: 200px !important;
}

.percent-slider-container {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  position: relative;
}

.percent-slider-container,
.percent-slider-container .percent-slider {
  height: 100%;
  transition: width 0.75s ease-in-out;
  min-height: 1.5em;
}

.percent-slider .value {
  position: absolute;
  left: 0.5em;
  top: 1px;
}

.percent-slider-container .percent-slider.fast {
  transition: width 0.5s linear;
}

.percent-slider-container .percent-slider.slow {
  transition: width 3s linear;
}

.percent-slider-container.progress-hidden {
  width: 0 !important;
}

.member-dashboard-assignments .Collapsible.assignments-show-hide .grid {
  margin: 0;
}

.member-dashboard-assignments .Collapsible.assignments-show-hide .assignment-row.has-updates {
  padding-top: 0.75rem;
}

.member-dashboard-assignments .assignments-show-hide .responsive-table-container {
  margin: 0 !important;
}

.member-dashboard-assignments .assignments-show-hide .admin-table {
  border-collapse: collapse;
}

.member-dashboard-assignments .assignments-show-hide .admin-table:hover {
  cursor: default;
}

.member-dashboard-assignments .assignments-show-hide .admin-table tfoot {
  border-top: 2px solid rgba(34, 36, 38, 0.1);
}

.member-dashboard-assignments .assignments-show-hide .summary-row {
  font-weight: 600;
}

.member-dashboard-assignments .assignments-show-hide .ui.middle.aligned.grid.expanded-assignments-heading {
  background: #f4f6f8;
  font-size: 0.875em;
}

.member-dashboard-assignments .assignments-show-hide .ui.middle.aligned.grid.expanded-assignments-heading:hover {
  cursor: default;
}

.member-dashboard-assignments .Collapsible:hover {
  cursor: pointer;
}

.member-dashboard-assignments .assignments-container,
.member-dashboard-learning .learning-container {
  margin-top: 1em;
}

.member-dashboard-assignments .assignments-container .assignments-container {
  margin-top: 0;
}

.member-dashboard-assignments .filter-dropdown,
.member-dashboard-learning .filter-dropdown {
  margin-left: 1em;
}

.ui.segment.member-dashboard-learning:last-child {
  margin-bottom: 4em;
}

.member-dashboard-assignments .assignments-show-hide {
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-radius: 2px;
}

.member-dashboard-assignments .assignments-show-hide .Collapsible__trigger .row > div:first-child {
  font-weight: 700;
  font-size: 1.125em;
}

.member-dashboard-assignments .assignments-show-hide .expanded-assignments-heading > .row {
  padding: 0.5em 0;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.member-dashboard-assignments .assignments-show-hide .icon.show-hide-chevron {
  margin-left: 0.5em;
}

.member-dashboard-assignments .assignments-show-hide .icon.show-hide-chevron:before {
  content: '\F054';
}

.member-dashboard-assignments .assignments-show-hide .is-open .icon.show-hide-chevron:before {
  content: '\F078';
}

.dashboard .progress-label.ui.yellow.label,
.enrollments .progress-label.ui.yellow.label,
.member-dashboard-assignments .assignments-show-hide .Collapsible__trigger .label.yellow {
  color: #141414 !important;
}

.enrollments .assignments-container .assignment-row .certs-container .icon {
  font-size: 1.25em;
}

.enrollments .expanded-assignments-table .no-assignment-activity {
  padding: 1em;
}

/* Set widths on columns for enrollments tables - To help get columns somewhat close to lines up */
.enrollments .expanded-assignments-table tr > td:nth-child(1),
.dashboard .expanded-assignments-table tr > td:nth-child(1),
.dashboard .member-dashboard-learning tr > td:nth-child(1) {
  width: 40%;
  max-width: 200px;
}

.enrollments .expanded-assignments-table tr > td:nth-child(2),
.dashboard .expanded-assignments-table tr > td:nth-child(2),
.dashboard .member-dashboard-learning tr > td:nth-child(2) {
  width: 10%;
}

.enrollments .expanded-assignments-table tr > td:nth-child(3),
.dashboard .expanded-assignments-table tr > td:nth-child(3),
.dashboard .member-dashboard-learning tr > td:nth-child(3) {
  width: 10%;
}

.enrollments .expanded-assignments-table tr > td:nth-child(4),
.dashboard .expanded-assignments-table tr > td:nth-child(4),
.dashboard .member-dashboard-learning tr > td:nth-child(4) {
  width: 10%;
}

.enrollments .expanded-assignments-table tr > td:nth-child(5),
.dashboard .expanded-assignments-table tr > td:nth-child(5),
.dashboard .member-dashboard-learning tr > td:nth-child(5) {
  width: 10%;
}

.enrollments .expanded-assignments-table tr > td:nth-child(6),
.dashboard .expanded-assignments-table tr > td:nth-child(6),
.dashboard .member-dashboard-learning tr > td:nth-child(6) {
  width: 5%;
}

.enrollments .expanded-assignments-table tr > td:empty {
  padding: 0 !important;
}

.enrollments .progress-message {
  text-align: right;
}

.member-dashboard-assignments .assignments-show-hide .expanded-assignments-heading {
  border: 1px solid rgba(34, 36, 38, 0.1);
  background: #f4f6f8;
}

.member-dashboard-assignments .assignments-show-hide .expanded-assignments-heading .row {
  padding: 0.25em 0;
  border: none;
}

.content-dashboard .header-container .content-description {
  margin-bottom: 1em;
}

.content-dashboard .header-container .content-labels-container .label {
  margin-right: 1em;
}

.dashboard .ui.cards.meta-cards > .card {
  text-align: center;
}

.proficient-badge {
  display: inline-block;
  width: 40px;
  height: 40px !important;
  background-image: url('https://images.ctfassets.net/kvf8rpi09wgk/3kuDY9wnmXCgvFHqLfPTvw/56f97a106b2788f2c5ae7eaf6261d837/Cybrary_mark_black.svg?h=250'),
    linear-gradient(to right, #fff, #fff 50%, #eaeaea 50%);
  visibility: visible !important;
  border-radius: 50%;
  border: 4px solid #e14696;
}

.dashboard .competency-levels .skill-bar-container .proficient-badge {
  width: 35px;
  height: 35px !important;
  vertical-align: middle;
  margin-left: 1em;
}

.dashboard .competency-levels .skill-bar-container .skill-search-link {
  margin-left: 1em;
  font-size: 0.75rem;
}

.dashboard .assessment-table .proficient-badge {
  position: absolute;
  left: -3em;
  top: -0.325em;
  width: 35px;
  height: 35px !important;
}

.dashboard .stat-card.proficient-count .statistic .value span:before {
  content: '';
  width: 40px;
  height: 40px !important;
  position: absolute;
  background-image: url('https://images.ctfassets.net/kvf8rpi09wgk/3kuDY9wnmXCgvFHqLfPTvw/56f97a106b2788f2c5ae7eaf6261d837/Cybrary_mark_black.svg?h=250'),
    linear-gradient(to right, #fff, #fff 50%, #eaeaea 50%);
  left: -0.675em;
  top: 0.19em;
  visibility: visible !important;
  border-radius: 50%;
  border: 4px solid #e14696;
}

.dashboard .stat-card.proficient-count .statistic .value span {
  position: relative;
}

.dashboard .ui.cards.meta-cards > .card .header {
  font-size: 0.875em !important;
  font-weight: 300 !important;
}

.dashboard .ui.cards.meta-cards > .card .description {
  font-weight: 600;
  font-size: 1.5em;
}

.competency-levels h2 {
  margin-top: 0 !important;
}

.competency-levels .empty-message {
  padding: 2em;
  color: #637381;
}

.competency-levels .skill-breakdown > .row:first-child {
  margin-top: 1em;
  margin-bottom: 1.25em;
}

.competency-levels .skill-breakdown > .row:first-child .skill-name {
  font-size: 1.125em;
  font-weight: 600;
}

.competency-levels .skill-breakdown .skill-desc {
  font-size: 0.875em;
}

.competency-levels .skill-breakdown .skill-bar-container {
  height: 100%;
  display: inline-block;
  margin-right: 0.5em;
}

.competency-levels .skill-breakdown .avg-label {
  font-size: 0.75em;
  margin-left: 0.5em;
  position: absolute;
}

.competency-levels .legend-container {
  text-align: center;
  margin-bottom: 2em;
}

.competency-levels .legend-container .legend-key-container {
  display: inline-block;
  padding: 0 1em;
}

.competency-levels .legend-container .legend-key-container .key-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.competency-levels .legend-container .legend-key-container span.key-title {
  font-size: 0.75em;
  margin-left: 6px;
}

.competency-levels .legend-container .legend-key-container > div {
  margin-bottom: -6px;
}

.competency-levels .legend-container .legend-key-container span.key-title.subtitle {
  margin-left: 20px;
}

.competency-breakdown-item > span {
  margin-right: 10px;
  margin-bottom: 10px;
}

.competency-breakdown-item .color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 7px;
  vertical-align: middle;
}

.competency-breakdown-item .percent {
  font-weight: 600;
}

@media screen and (max-width: 1250px) {
  .stat-cards-group .card.stat-card .summary-container > .ui.statistic > .value {
    font-size: 3em !important;
  }

  .stat-cards-group .card.stat-card .summary-container.small > .ui.statistic > .value {
    font-size: 2.5em !important;
  }
}

@media screen and (max-width: 1100px) {
  .stat-cards-group.five.cards > .card.stat-card {
    width: calc(30% - 1.5em);
    margin-left: 0.75em;
    margin-right: 0.75em;
  }

  .assignments-show-hide .ui.grid > .row > .column.progress-text-container {
    font-size: 0.875em;
  }

  .stat-cards-group.four.cards > .stat-card {
    width: calc(50% - 1.5em) !important;
    margin-left: 0.75em !important;
    margin-right: 0.75em !important;
  }

  .stat-cards-group .card.stat-card .summary-container.small > .ui.statistic > .value {
    font-size: 3.25rem !important;
  }

  .stat-cards-group .card.stat-card .summary-container.medium > .ui.statistic > .value {
    font-size: 4rem !important;
  }

  .stat-cards-group .card.stat-card .summary-container.large > .ui.statistic > .value {
    font-size: 5rem !important;
  }
}

@media screen and (max-width: 991px) {
  .member-dashboard-assignments .assignments-container .expanded-assignments-heading.ui.stackable.grid > .row > .column {
    padding: 0.5em 1em !important;
    display: block;
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .enrollments .progress-message {
    text-align: left;
  }

  .competency-levels .skill-breakdown.ui.stackable.grid > .row > .column:first-child {
    padding-bottom: 0 !important;
  }

  .organization .dashboard .stat-container .stat-links {
    margin-right: 0;
  }

  .stat-cards-group .card.stat-card .summary-container.small > .ui.statistic > .value {
    font-size: 4rem !important;
  }

  .stat-cards-group .card.stat-card .summary-container.medium > .ui.statistic > .value {
    font-size: 4.5rem !important;
  }

  .stat-cards-group .card.stat-card .summary-container.large > .ui.statistic > .value {
    font-size: 5rem !important;
  }
}

@media screen and (max-width: 675px) {
  .member-dashboard-assignments .assignments-container .assignment-row.ui.stackable.grid > .row > .wide.column {
    text-align: left !important;
    padding: 0.5em 1em !important;
  }

  .member-dashboard-assignments .assignments-container .assignment-row {
    padding: 0.5em 0;
  }

  .member-dashboard-assignments .assignments-container .assignment-row.ui.stackable.grid > .row > .wide.column.four,
  .member-dashboard-assignments .assignments-container .assignment-row.ui.stackable.grid > .row > .wide.column.three {
    width: 50% !important;
    display: inline-block;
  }

  .member-dashboard-assignments .mobile-align-left,
  .member-dashboard-learning .mobile-align-left,
  .leaderboard-container .mobile-align-left {
    text-align: left !important;
  }

  .stat-container {
    padding: 1em !important;
  }

  .organization .dashboard .stat-container .stat-links {
    margin-right: -0.75em;
  }
}

@media screen and (max-width: 450px) {
  .assignments-show-hide .ui.grid > .row > .column.progress-label-container {
    text-align: left !important;
    width: 100% !important;
  }

  .assignments-show-hide .ui.grid > .row > .column.certs-container {
    text-align: right !important;
    width: 25% !important;
  }

  .assignments-show-hide .ui.grid > .row > .column.progress-text-container {
    width: 75% !important;
    margin: 0.5rem 0;
  }
}
