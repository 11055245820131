.create-assignment .ui.input {
  width: 100%;
}

.enterprise-assignment .table-user-list {
  margin-bottom: 0;
  margin-left: 2em;
}

.enterprise-assignment .ui.accordion.table-user-showhide .title:not(.ui) {
  padding: 0;
}

.assignment-view-members .description {
  width: 100%;
}

.enterprise-assignment .filter-options.segment {
  margin-bottom: 2em;
}
