.admin-larp {
  position: fixed;
  bottom: -5.25em;
  right: 6em;
  z-index: 9000;
  width: auto !important;
  transition: all 0.5s ease;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 1s;
}

@-webkit-keyframes slide {
  100% {
    bottom: -0.25em;
  }
}

@keyframes slide {
  100% {
    bottom: -0.25em;
  }
}

.admin-larp .larp-icon-container {
  color: #4183c4;
  opacity: 0.5;
  transition: all 0.5s ease;
  display: inline-block;
}

.admin-larp .ui.segment {
  padding: 0;
  position: relative;
}

.admin-larp:hover .larp-icon-container {
  opacity: 1;
}

.admin-larp:hover .larp-icon-container .icon {
  font-size: 4.5em;
}

.admin-larp .larp-icon-container .icon {
  font-size: 4em;
  line-height: 1em;
  margin: 0;
  transition: all 0.5s ease;
}

.admin-larp .larp-controls-container {
  opacity: 0;
  position: absolute;
  right: 5em;
  bottom: 1em;
  color: #fff;
  text-align: center;
  background-color: #7ac4f9;
  border-radius: 10px;
  display: inline-block;
  -webkit-transition: opacity 0s linear;
  -o-transition: opacity 0s linear;
  transition: opacity 0s linear;
  transition-delay: 0s;
  width: 0;
}
.admin-larp .larp-controls-container:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 25%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #7ac4f9;
  border-right: 0;
  border-bottom: 0;
  margin-top: -5px;
  margin-right: -10px;
}

.admin-larp:hover .larp-controls-container {
  opacity: 1;
  width: auto;
  min-width: 25em;
  padding: 0.75em 1.5em;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.admin-larp .team-info {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.admin-larp .team-info .yellow {
  color: #ffff00;
}

.admin-larp .larp-controls-container .button .icon {
  margin: 0 !important;
}

.admin-larp .larp-controls-container .button {
  display: inline-block;
  margin-left: 1em;
  padding: 0.8em;
}

.admin-larp .larp-controls-container .dropdown .text {
  color: #637381;
}

.admin-larp .larp-controls-container .toggle-release-container {
  margin-top: 1em;
}

.admin-larp .larp-controls-container .release-label {
  display: inline-block;
  margin-left: 1em;
  vertical-align: super;
}

.admin-larp .larp-controls-container .toggle-release-container .yellow {
  color: #ffff00;
  font-weight: 600;
}

.admin-larp .larp-controls-container .toggle-release-container .green {
  color: #0db350;
  font-weight: 600;
}

.ui.popup.larp-close-popup {
  z-index: 10000;
}
