.goals-list .goal-participants {
  max-width: 300px;
}

.goals-list .goal-progress .percentage {
  width: calc(100% - 3.5em);
  display: inline-block;
  background-color: #f4f6f8;
  border-radius: 6px;
  max-height: 0.5em;
  overflow: hidden;
  margin-right: 1em;
  min-width: 4rem;
}

.goals-list .goal-progress .progress {
  font-size: 0.875em;
}

.goals-list .goal-progress .percent-slider-container {
  vertical-align: top;
}
