.content-update-modal {
  max-width: 750px;
}

.content-update-modal .content-update-summary .summary-topic {
  margin: 0;
}

.content-update-modal .content-update-summary .summary-content .details {
  margin-bottom: 1.5em;
}

.content-update-modal .confirm-content-switch .buttons-container {
  text-align: right;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding-top: 1em;
}

.content-update-modal .confirm-content-switch .buttons-container .button + .button {
  margin-left: 1em;
}

.content-update-summary .summary-headers {
  text-align: left;
  padding: 0.5em 0;
}

.content-update-summary .summary-headers .column {
  padding: 0 !important;
}

.content-update-summary .summary-headers .header {
  margin: 0;
}

.content-update-summary .summary-headers h4 {
  font-weight: 600;
}

.content-update-summary .summary-headers h5 {
  font-size: 0.875em;
}

.content-update-summary .progress-container {
  font-size: 0.75em;
}

.content-update-summary .progress-container p {
  margin: 0;
}

.content-update-summary .summary-headers > * {
  margin: 0 !important;
  font-weight: 600;
}

.content-update-summary .column-heading {
  font-weight: 600;
  text-decoration: underline;
}

.content-update-summary .content-changelog > .header {
  margin-bottom: 0.5em;
}

.content-update-summary .content-changelog .changelog-details {
  max-height: 15em;
  margin-top: 0.5em;
  overflow-y: scroll;
  font-size: 0.875em;
  background-color: #f4f6f8;
}

.content-update-summary .content-changelog .change-type {
  margin-bottom: 1em;
}

.content-update-summary .content-changelog .change-type > p {
  font-weight: 600;
  margin-bottom: 0.25em;
}

.content-update-summary .content-changelog .change-type .content-types {
  margin-left: 2em;
  margin-bottom: 0.5em;
}

.content-update-summary .content-changelog .change-type .content-types > p {
  margin-bottom: 0.25em;
}

.content-update-summary .content-changelog .change-type .content-types .list {
  margin-top: 0;
  margin-left: 2em;
}

.content-update-modal .modal-close {
  text-align: right;
  padding: 1em;
}

.ui.message.content-update-banner {
  background: #166bf4;
  color: #fff;
  font-size: 0.875em;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}

.content-update-banner .notifications-count.ui.label {
  background-color: #166bf4;
  color: #fff;
  font-weight: 600;
  font-size: 1.125em;
  padding: 0 0.35em;
}

.content-update-flag {
  cursor: pointer;
  margin-left: 0em !important;
}

.content-update-flag.tab {
  margin-left: 0.5em !important;
  font-size: 0.875rem;
  background-color: #166bf4;
  border-radius: 2px;
  color: #fff;
  padding: 0.25rem;
}

.assignments-container .content-update-flag.tab {
  margin-left: 0em !important;
}

.content-update-flag.no-click {
  cursor: default;
}

.content-update-flag.tab.no-click {
  color: #166bf4;
  background: #fff;
  box-shadow: none;
}

.assignments-show-hide .content-update-flag.tab {
  top: -1.25em;
  border-top-left-radius: 10px;
  padding: 0 1em;
}

@media screen and (max-width: 991px) {
  .assignments-show-hide .content-update-flag.tab {
    top: -1.25em;
  }
}

@media screen and (max-width: 768px) {
  .content-update-banner .grid .column {
    text-align: center !important;
  }
}
