.admin .admin-table-search .ui.input {
  width: 75%;
}

.admin .responsive-table-container {
  font-size: 0.9375em;
}

.admin-view-team .header-container .label {
  vertical-align: top;
}

.admin-view-team .view-team-header {
  display: inline-block;
}

.admin-view-team .view-team-header + .label {
  vertical-align: top;
  margin-left: 1.5em !important;
}

.admin-view-team .view-team-img img {
  border-radius: 50%;
  width: 80%;
}
.admin-view-team .block {
  display: block;
}

.admin-view-team .invite-url input {
  overflow-x: scroll;
  background: transparent;
  border: none;
  color: #2478c2;
  padding: 0.5em;
  width: calc(100% - 4em);
}

.admin-view-team .invite-url .icon:hover {
  color: #2ecc40;
  cursor: pointer;
}

.admin-view-team .invite-url .icon {
  margin-right: 0.5em;
  font-size: 1.75em;
  vertical-align: text-top;
}

.admin-view-team .search .ui.basic.button {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: -2.75em;
}

.admin-view-team .responsive-table-container {
  margin-top: 1em !important;
}

.admin-view-team .view-team-options .button {
  display: inline-block;
}

.admin-view-team .view-team-options .ui.fluid.dropdown {
  width: 30%;
  display: inline-block;
  margin-left: 1em;
}

.view-team-add-members.ui.basic.modal .admin-add-members {
  color: #141414;
}

.view-team-add-members.ui.basic.modal .admin-add-members .dynamic-field-desc {
  padding-top: 1em;
  padding-left: 1em;
}

.view-team-add-members .admin-add-members .responsive-table-container {
  overflow: visible;
  min-height: 16em;
}

.admin-view-team .ui.grid > .row.row-no-padding {
  padding: 0;
}

.admin-view-team .no-results,
.view-team-add-members .admin-add-members .no-results {
  margin-top: 2em;
  font-style: italic;
}

.admin-view-team .team-logo {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.admin-view-team .team-logo img {
  width: 100%;
}

.admin-view-team .team-logo .icon {
  color: #fff;
  font-size: 2em;
  line-height: 1em;
  vertical-align: bottom;
  opacity: 0;
  margin: auto;
  transition: all 0.5s ease;
}

.admin-view-team .team-logo.empty .icon {
  opacity: 1;
}

.admin-view-team .team-logo:not(.disabled):hover {
  opacity: 0.7;
  cursor: pointer;
}

.admin-view-team .team-logo:not(.disabled):hover .icon {
  opacity: 1;
}

.admin-view-team .details-container p {
  margin-bottom: 0;
}

.admin-view-team .details-container .details-item {
  font-weight: 700;
}

.admin-view-team .details-container .active {
  color: #0db350;
}

.admin-view-team .details-container .inactive {
  color: #ed1e44;
}

@media screen and (max-width: 675px) {
  .organization .view-team-options .ui.fluid.dropdown {
    width: 60%;
    margin-left: 0;
  }

  .admin .admin-table-grid .admin-add-team {
    width: 100%;
  }

  .admin .admin-table-grid.ui.stackable.grid > .row > .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .admin-view-team .button,
  .admin-view-team .view-team-options > .column > .ui.fluid.dropdown,
  .admin-view-team .view-team-options > .column > form .input {
    width: 100%;
    margin-left: 0;
  }

  .admin-view-team .search .button {
    width: auto;
  }

  .admin-view-team .invite-url input {
    overflow-x: scroll;
  }

  .admin-view-team .view-team-options > .column > form .input {
    width: calc(100% - 1px);
    margin: 0;
  }

  .admin-view-team .view-team-options .ten > .button + * {
    margin-top: 1em;
  }
}
