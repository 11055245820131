.series-overlay {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  width: 100%;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2)
  );
}

.series-display-content {
  margin: 5vh 4vw;
}

.live-dark-description p,
.live-dark-description ul,
.live-dark-description ol,
.live-dark-description h1,
.live-dark-description h2,
.live-dark-description h3,
.live-dark-description h4,
.live-dark-description h5,
.live-dark-description h6,
.live-dark-description a {
  color: white !important;
}

@media (min-width: 768px) {
  .series-display-content {
    margin: 15vh 10vw;
  }
}

.live-box {
  animation: blinker 1s linear infinite;
}

.rowTitle {
  margin: 0 0 2em;
}

.rowTitle.hover-link h2,
.rowTitle.hover-link h3 {
  cursor: pointer;
}

.rowTitle.hover-link h2 .hover-text-container {
  background: transparent;
  outline: none;
  position: relative;
  overflow: hidden;
}

.rowTitle.hover-link h2 .hover-text-container .hover-text {
  color: rgba(0, 0, 0, 0); /* transparent */
}

.rowTitle.hover-link:hover h2 .hover-text-container:before {
  opacity: 1;
  transform: translate(0, 0);
}
.rowTitle.hover-link h2 .hover-text-container:before {
  content: attr(data-hover);
  position: absolute;
  top: 0.1em;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 0.7em;
  opacity: 0;
  transform: translate(-100%, 0);
  transition: all 0.3s ease-in-out;
  margin-left: 1em;
}
.rowTitle.hover-link:hover h2 .hover-text-container .hover-text {
  opacity: 0;
  transform: translate(100%, 0);
}

.rowTitle.hover-link h2 .hover-text-container .hover-text {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 0.7em;
  transition: all 0.3s ease-in-out;
  margin-left: 1em;
}

.live-card {
  transition: transform 0.2s; /* Animation */
}

.preview-card-container {
  margin: 0;
  width: 100%;
  text-align: center;
  height: auto;
}

.preview-card-header-image {
  left: 0;
  position: absolute;
  background-size: cover;
  opacity: 0.6;
  text-align: center;
  height: 100px;
  width: 100%;
}

.preview-card-center-image {
  position: relative;
  background: #fff;
  border: 5px solid white;
  margin-bottom: -50px;
  margin-top: 50px;
  height: 100px;
}

.preview-card-content-container {
  text-align: left;
  width: 100%;
  padding: 1em 1em;
}

.live-card-image {
  height: 100% !important;
  width: 100% !important;
}

.detailsBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 1em;
  /* transition effect. not necessary */
  transition: opacity 0.1s, visibility 0.1s;
}

.live-card:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.live-card:hover .detailsBlock {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.selectable-row:hover {
  background: #e0e1e2 !important;
}

.title-card-container {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .title-card-container {
    display: block;
  }
  .feedback-button-container {
    margin: 1em 0 0;
  }
}
