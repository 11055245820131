.paddle-wrapper > iframe {
  min-width: 500px;
  margin: 25px;
}

/** apply to <lg tailwind breakpoint */
@media (max-width: 1024px) {
  .paddle-wrapper > iframe {
    min-width: 100%;
    margin: 0px;
  }
}
