.details-header {
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.details-header.lightgrey.text {
  color: #dcddde !important;
}

@media screen and (max-width: 768px) {
  .details-header {
    font-size: 0.8em;
  }
}

.details-content.text-gray-600 p {
  color: #707070;
}
