.styled-error .immersive-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.styled-error .immersive-error-header,
.styled-error .immersive-error-content {
  color: #fff !important;
}

.styled-error .immersive-error-container,
.styled-error .upgrade-paywall {
  height: calc(100vh - 284px);
}

.styled-error .error-text-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.styled-error .error-text-container .error-text {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.styled-error .styled-error-overlay {
  position: relative; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
