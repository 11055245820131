.career-path.career-path > .ui.stackable.grid {
  margin: -1em 0 !important;
}

.career-path > .ui.stackable.grid > .row {
  padding: 2.857em 0;
}

.career-path .join-today .container {
  padding: 0;
}

.career-path ul,
.career-path p {
  line-height: 1.65em;
}

.career-path h4 {
  font-size: 1.428em;
}

.career-path .row.bg-white {
  background-color: #fff;
}

.career-path .container + .divider {
  margin-top: 4em;
}

.career-path > .ui.stackable.grid > .row.banner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000000;
  background-attachment: scroll;
  background-size: auto;
  padding: 10% 0 7em 0;
}
.career-path .banner-container.network-engineer {
  background-image: url('https://www.cybrary.it/wp-content/uploads/2018/04/net_eng_background_2.jpg');
}

.career-path .banner-container.soc-analyst {
  background-image: url('https://www.cybrary.it/wp-content/uploads/2018/03/soc_background.jpg');
}

.career-path .banner-container.pen-tester {
  background-image: url('https://www.cybrary.it/wp-content/uploads/2018/04/pen_test_background.jpg');
}

.career-path .banner-container.cyber-security {
  background-image: url('https://www.cybrary.it/wp-content/uploads/2018/04/sec_eng_background.jpg');
}

.career-path .career-skill {
  background: #f9f9f9;
}

.career-path .career-skill-highlight {
  background: #f0f0f0;
}

.career-path .career-skill li {
  line-height: 1.2em;
  margin-bottom: 4px;
  font-weight: 400;
}

.banner-container > div {
  padding: 0 !important;
}

.banner-container p,
.banner-container h1 {
  width: 60%;
}

.banner-container p,
.banner-container h1 {
  color: #fff;
  font-size: 1.2142em;
}

.banner-container .buttons-container {
  margin: 4em 0;
}

.banner-container .buttons-container .button {
  vertical-align: middle;
}

.career-path .banner-container h1 {
  font-size: 2.5662em;
  /*font-family: 'proxima-nova' !important;*/
  font-weight: 800;
}

.career-path .text-yellow {
  color: #ffff00;
}

.career-path .career-skill .keyHighlightText ul {
  font-size: 1em;
  line-height: 1.65em;
}

.career-path .button .text-yellow {
  font-weight: 500;
}
.banner-container .buttons-container button {
  height: 55px;
  min-width: 250px;
  font-size: 1.1428em;
}

.buttons-container a + a {
  margin-left: 1em;
}

.buttons-container p {
  margin-top: 1em;
}
.ui.basic.segment.centered {
  text-align: center;
}

.career-path p {
  line-height: 1.65em;
}

.career-path .join-today button {
  font-size: 1.714em;
  padding: 0.75em 2.5em;
  margin-bottom: 0.5em;
}

.career-path .join-today button .text-yellow {
  margin-top: 0.5em;
}

.career-path .three .column + .column {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.program-info .text-pink {
  color: #e14696;
  font-size: 1em;
}

.program-info .text-pink + p {
  font-size: 1.428em;
}

.program-info .text-pink + p + p {
  font-size: 0.9285em;
}

.how-to-become p {
  margin-bottom: 0;
}

.three-columns img {
  margin-top: 1em;
}

p + .heading {
  margin-top: 1em;
}

@media screen and (max-width: 585px) {
  .buttons-container a {
    display: inline-block;
  }

  .buttons-container a + a {
    margin-left: 0;
    margin-top: 1em;
  }
}

@media screen and (max-width: 990px) {
  .banner-container p,
  .banner-container h1 {
    width: 100%;
  }

  .four-icons .column {
    padding: 2em 0;
  }

  .how-to-become .column + .column {
    margin-top: 2em !important;
  }

  .career-path .ui.grid > [class*='five wide'].column,
  .career-path .ui.grid > [class*='eleven wide'].column {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .career-path .ui.container.app-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
