.settings .ui.segment {
  border: 0px;
}

.settings .ui.card,
.settings .ui.cards .card {
  width: 315px !important;
}

.settings .red-delete-button:hover {
  background: #ed1e44 !important;
  color: #fff !important;
}

.settings-collapsible-container .ui.segment {
  border-bottom: 1px solid #d1d5db;
}

.settings .settings-collapsible-container .setting-container .Collapsible .icon-container i {
  -webkit-transition: transform 200ms;
  transition: transform 200ms;
}

.settings .settings-collapsible-container .setting-container .Collapsible .icon-container i.expanded {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Safari prior 9.0 */
  transform: rotateZ(180deg);
}

.settings .settings-collapse-content {
  width: 100%;
}

.settings .settings-collapse-content .right-sub-title {
  float: right;
  color: #80808095;
}

.settings .add-email-container {
  margin-top: 2em;
}

.settings .email-management .link-look,
.email-alert-modal .alert-action {
  color: #df057b;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
}

.settings .email-management input {
  min-width: 30rem;
  margin-bottom: 1em !important;
}

.settings .email-management .emails-list > .item {
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1em 0.25em !important;
  display: flex;
}

.settings .email-management .emails-list > .item .unverified .icon {
  margin-left: 0.25em;
  color: #4183c4;
  cursor: pointer;
}

.settings .email-management .emails-list > .item .primary,
.settings .email-management .emails-list > .item .unverified {
  font-weight: 600;
  color: #80808095;
}

.settings .email-management .emails-list > .item > .content {
  flex-grow: 1;
}

.settings .email-management .emails-list > .item > .email-actions,
.settings .email-management .emails-list > .item > .content.primary {
  text-align: right;
}

.settings .email-management .emails-list > .item > .email-actions .make-primary,
.settings .email-management .emails-list > .item > .email-actions .unverified,
.settings .email-management .emails-list .reset-password {
  margin-right: 2em;
}

.cip-cancel-modal {
  max-width: 600px;
}

.cip-cancel-modal .cancel-question {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cip-cancel-modal .features-list div {
  margin-bottom: 0.25em;
}

.cip-cancel-modal .features-list .icon {
  color: #ed1e44;
}

.cancellation-timeline {
  margin: 3em 1.5em;
}

.cancellation-timeline p {
  color: #aaa;
}

.cancellation-timeline .expiration-date {
  display: flex;
  margin-left: -0.5em;
  justify-content: space-between;
  width: 63%;
}

.cancellation-timeline .end-subscription {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.cancellation-timeline .end-subscription .active {
  color: #0db350;
}

.cancellation-timeline .line {
  border-bottom: 2.5px solid #e5e5e5;
  position: relative;
}

.cancellation-timeline .line:after {
  content: '';
  display: block;
  border-bottom: 2.5px solid #0db350;
  margin-bottom: -2px;
  max-width: 50%;
}

.cancellation-timeline .line .left-circle,
.cancellation-timeline .line .right-circle {
  height: 15px;
  width: 15px;
  position: absolute;
  background-color: #0db350;
  top: -6px;
  border-radius: 50%;
  display: inline-block;
}

.cancellation-timeline .line .left-circle {
  left: 50%;
}

@media only screen and (max-width: 768px) {
  .settings .email-management input {
    min-width: 100%;
  }

  .settings .email-management .input {
    width: 100%;
  }

  .settings .email-management .emails-list .item,
  .settings .email-management .emails-list .item .content {
    display: block;
  }

  .settings .email-management .emails-list > .item > .email-actions,
  .settings .email-management .emails-list > .item > .primary {
    margin-top: 1em;
  }

  .settings .email-management .emails-list > .item > .email-actions,
  .settings .email-management .emails-list > .item > .content.primary {
    text-align: left;
  }

  .cip-cancel-modal .features-list.ui.stackable.grid > .column.row > .column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .email-alert-modal p {
    white-space: normal;
    margin-bottom: 1em !important;
  }
}

@media only screen and (max-width: 425px) {
  .cancellation-timeline .expiration-date {
    width: 75%;
  }
}

@media only screen and (max-width: 375px) {
  .cancellation-timeline .expiration-date {
    width: 88% !important;
  }
}
